import React, { useState } from 'react';
import Company from './Company';
import data from './data';

const Experience = () => {
    const [company, setCompany] = useState(data);
    return (
        <div className="block-resume col-xs-12 col-sm-12 col-lg-12">
            <div className="p-20" />
            <div className="block-icon">
                <i class="flaticon-suitcase skill-icon"></i>
            </div>
            <div className="block-title">
                <h2>Experience</h2>
            </div>
            <div id="timeline_2" className="timeline clearfix row">
                <Company company={company} />
            </div>
        </div>
    )
}

export default Experience
