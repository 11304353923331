import React from 'react';
import {Link} from 'react-router-dom';

const Header = () => {
    
    return (
        <header id="site_header" className="header">
            <div className="header-content clearfix">
                <div className="text-logo">
                        <Link to="/">
                            <div className="logo-icon">
                                <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="" />
                            </div>
                        </Link>
                </div>
                
                <div className="site-nav">
                    <ul className="leven-classic-menu site-main-menu">
                        <li className="menu-item">
                            <Link to="/resume" className="btn btn-primary">Resume</Link>
                        </li>
                    </ul>
                </div>

                
          </div>
        </header>
    );
};

export default Header;
