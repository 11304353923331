import React from 'react'

const Testy = ({ content }) => {
    return (
    <>
            {content.map((item) => {
                const { id, name, title, img, testy } = item;
            return (
                <div key={id} className="testimonial-item col-md-12 col-lg-6">
                    <div className="testimonial-content">
                        <div className="testimonial-picture">
                            <img src={img} alt={name} />
                        </div>
                        <div className="testimonial-text">
                            <p>{testy}</p>
                        </div>
                        <div className="testimonial-icon">
                            <i className="fa fa-quote-left" />
                        </div>
                        <div className="testimonial-author-info">
                            
                            <h5 className="testimonial-author">{name}</h5>
                            <p className="testimonial-firm">{title}</p>
                        </div>
                    </div>
                </div>
            );
            
        })}
    </>
    );

}

export default Testy
