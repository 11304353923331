import React, { useState } from 'react';
import data from './data.js';
import Testy from './Testy';

const Testimonials = () => {
    const [content, setContent] = useState(data)
    return (
        <>
            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="p-70" />
                    <div className="block-title">
                            <h2>Testimonials</h2>
                        </div>
                    <div id="testimonials_1" className="testimonials row">
                        <Testy content={content}/>
                    </div>
                </div>
             </div>
        
        </>
    )
}

export default Testimonials
