import React from 'react';
import About from '../about/About';
import Expertise from '../expertise/Expertise';
import Portfolio from '../portfolio/Portfolio';
import Testimonials from '../testimonials/Testimonials';

const Home = () => {
    return (
        <>
            <div id="main" className="site-main">
                <div id="main-content" className="single-page-content">
                    <div id="primary" className="content-area">
                        <div id="content" className="page-content site-content single-post" role="main">
                        <About/>
                        <Expertise/>
                        <Portfolio/>
                            <Testimonials/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
