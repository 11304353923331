import React, { useState } from 'react';
import Project from './Project';
import Categories from './Categories';
import items from './data';
const allCategories = ['all', ...new Set(items.map((item) => item.category))];

function App() {
    const [projects, setProjects] = useState(items);
    const [categories, setCategories] = useState(allCategories);

    const filterItems = (category) => {
        if (category === 'all') {
            setProjects(items);
            return;
        }
        const newItems = items.filter((item) => item.category === category);
        setProjects(newItems);
    };

    return (
        
        <div className="row">
            <div className=" col-xs-12 col-sm-12 ">
                <div className="p-50" />
                <div className="block-title">
                    <h2>Projects</h2>
                </div>
                <div className="page-content single-post">
                    <div id="portfolio_content_q" className="portfolio-content">
                        <Categories categories={categories} filterItems={filterItems} />
                        <Project projects={projects} />
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default App;
