const data = [
    {
        id: 1,
        name: 'Dr. Elvis Asong',
        title: 'President at Climalogik Inc.',
        img: '/img/elvis.jpg',
        testy: 'I have been fortunate to have Trupti design my website. The experience was memorable and different from other developers I came across. Her attention to details, skill set, client-first policy and passion for what she does make her an outstanding web developer. She developed this beautiful website https://elvisasong.com/ for me. There is so much flexibility available at my disposal to change website contents and update my stuff as needed. I strongly recommend her for your web tasks.',
    },
    {
        id: 2,
        name: 'Tim Yaworski',
        title: 'Owner of timkip imaging, Saskatoon.',
        img: '/img/tim.jpeg',
        testy: 'Trupti has the creative vision and technical skills to be able to create a web presence for my company that highlights the services I can offer my clients and help to build my business. Her ability to create a vibrant and interactive site coupled with her collaborative work style makes her a pleasure to work with.',
    },
];

export default data;