import React from 'react';
import data from './data';

const About = () => {
    const { title1, title2, name, intro1, link, img} = data;

    return (
        <div className="row">
            <div className=" col-xs-12 col-sm-12">
                <div className="home-content">
                    <div className="row flex-v-align">
                        <div className="col-sm-12 col-md-5 col-lg-4">
                            <div className="home-photo">
                                <div className="hp-inner" style={{ backgroundImage: 'url(' + `${process.env.PUBLIC_URL}` + img + ')' }}>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-8">
                            <div className="home-text hp-left">
                                <div className="owl-carousel text-rotation">
                                    <div className="item">
                                        <h4>{title1}</h4>
                                    </div>
                                </div>
                                <h2>{name}</h2>
                                <h3>{title2}</h3>
                                <p>{intro1}</p>
                                <div className="home-buttons">
                                    <a href={process.env.PUBLIC_URL + link} target="_blank" className="btn btn-primary" rel="noreferrer">View CV</a>
                                    <a href="https://www.linkedin.com/in/trupti-desale-4427712a/" target="_self" className="btn btn-secondary">LinkedIn</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
