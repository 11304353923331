import React from 'react'

const Footer = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    return (
        <footer className="site-footer clearfix">
            <div className="footer-copyrights">
                <p>© {year} All rights reserved. <a href="https://www.linkedin.com/in/trupti-desale-4427712a/">TD</a></p>
            </div>
        </footer>
    )
}

export default Footer
