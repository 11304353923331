const items = [
  {
    id: 1,
    title: 'Climalogik Consulting Inc',
    category: 'wordpress',
    img: '/img/portfolio/climalogik.png',
    link: 'https://climalogik.ca/',
  },
  {
    id: 2,
    title: 'timkip imaging Photography & Video Production',
    category: 'wordpress',
    img: '/img/portfolio/timkip.png',
    link: 'https://timkip.com/',
  },
  {
    id: 3,
    title: 'Book My Event App',
    category: 'MERN Stack',
    img: '/img/portfolio/book-my-events.png',
    link: 'https://mern-stack-event-frontend.herokuapp.com/',
  },
  {
    id: 4,
    title: 'Task Management App',
    category: 'PHP',
    img: '/img/portfolio/task-mgmt.png',
    link: 'http://task-management-crud-com.stackstaging.com/',
  },
  {
    id: 5,
    title: 'Sopan Kurkute Portfolio',
    category: 'wordpress',
    img: '/img/portfolio/sopankurkute.png',
    link: 'https://sopankurkute.com/',
  },
  {
    id: 6,
    title: 'Elvis Asong Portfolio',
    category: 'wordpress',
    img: '/img/portfolio/elvisasong.png',
    link: 'https://elvisasong.com/',
  },
  {
    id: 7,
    title: 'Trupti Desale Portfolio',
    category: 'react',
    img: '/img/portfolio/td-new.png',
    link: 'https://truptidesale.com/',
  },
  {
    id: 8,
    title: 'Trupti Desale Portfolio Old',
    category: 'wordpress',
    img: '/img/portfolio/td-old.png',
    link: 'http://truptidesale-com.stackstaging.com',
  },
  {
    id: 9,
    title: 'Twitter clone using MVC architecture',
    category: 'PHP',
    img: '/img/portfolio/twitter.png',
    link: 'http://getsocial-com.stackstaging.com/',
  },
  {
    id: 10,
    title: 'Vehicle repair shop.',
    category: 'wordpress',
    img: '/img/portfolio/cars.png',
    link: 'http://autoservices-com.stackstaging.com/',
  },
  
];
export default items;
