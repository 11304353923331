import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Resume from './components/resume/Resume';
import Home from './components/home/Home';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/resume" component={Resume} />
        </Switch>
        <Footer />
      </Router>
      
    </>
  );
}

export default App; 
