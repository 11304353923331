const data = [
    {
        id: 1,
        icon: 'phone',
        title: 'Front End Development',
        desc: 'Building the visual components of a website using HTML, CSS, and Javascript, I build fast, interactive and responsive websites. Comfortable working with Reactjs, Flexbox and CSS grid.',
    },
    {
        id: 2,
        icon: 'settings',
        title: 'Technical Support and Troubleshooting',
        desc: 'Focuses on optimizing websites to rank higher in search engines. Analyzes content and Google analytics data to improve search engine performance and increase website traffic.',
    },
    {
        id: 3,
        icon: 'display',
        title: 'Website Development',
        desc: 'Expert at building fully functional websites using Wordpress, Drupal, and Codeigniter PHP framework. Develop REST API, CRUD applications using PHP and Nodejs frameworks. Proficient at database operations using Mysql and MongoDB.',
    },
    {
        id: 4,
        icon: 'user',
        title: 'Consulting',
        desc: 'Understand clients requirments and provide them technical solutions. Consult about web hosting, SEO optimization, and Google analytics strategies.',
    },    
];

export default data;