import React from 'react'

const Education = () => {
    return (
        <div className="block-resume col-xs-12 col-sm-12 col-lg-12">
            <div className="block-icon">
                <i class="flaticon-graduation-cap skill-icon"></i>
            </div>
            <div className="block-title">
                <h2>Education</h2>
            </div>
            <div id="timeline_1" className="timeline clearfix">
                <div className="timeline-item clearfix">
                    <h5 className="item-period ">2003 - 2007</h5>
                    <span className="item-company">Pune University</span>
                    <h4 className="item-title">Bachelor of Engineering in Computer Engineering</h4>
                </div>
            </div>
        </div>
    )
}

export default Education
