const skills = [
    {
        id: 1,
        title: 'Front End',
        list: ["HTML5, CSS3","JavaScript, Reactjs","AJAX","SCSS","Bootstrap","CSS Grid and Flexbox"],
    },
    {
        id: 2,
        title: 'Back End',
        list: ["PHP, Codeigniter","Wordpress, Drupal","NodeJS, ExpressJS","MySql, MongoDB","REST API","MVC architecture"],
    },
    {
        id: 3,
        title: 'Workflow Tools',
        list: ["Github, Gitlab, Bitbucket", "Webpack, Gulp", "Ansible,Jenkins", "Shell scripting", "Docker, Netlify, Heroku, AWS"],
    },
    {
        id: 4,
        title: 'Best Practices',
        list: ["Object Oriented Programming","W3C standards","SEO","Google Analytics"],
    },
];
export default skills;
