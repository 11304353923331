import React, { useState} from 'react'
import Education from '../education/Education';
import Experience from '../experience/Experience';
import data from './skills';
import { FaAngleDoubleRight } from 'react-icons/fa';

const Resume = () => {
    const [skills, setSkills] = useState(data);
    const { id, title, list } = skills;
    return (
        <>
            <div id="main" className="site-main">
                <div id="main-content" className="single-page-content">
                    <div id="primary" className="content-area">
                        <div className="page-title">
                            <h1>Resume</h1>
                        </div>
                        <div id="content" className="page-content site-content single-post" role="main">
                            <Education />
                            <div className="block-resume col-xs-12 col-sm-12 col-lg-12">
                                <div className="block-icon">
                                    <i class="flaticon-pencil skill-icon"></i>
                                </div>
                            <div className="block-title">
                                <h2>Technical Skills</h2>
                            </div>
                            <div className="row block-outer">
                                    {skills.map((item,index)=>{
                                        return(
                                            <div key={index} className="col-sm-6 col-md-6 col-lg-3">
                                            <div className="block-item block-skills">
                                                <div className="block-title">
                                                    <h4>{item.title}</h4>
                                                </div>
                                                    <ul className="block-tools">
                                                        
                                                        {item.list.map((bull, int)=>{
                                                            return(
                                                                <li key={int}>
                                                                    {/* <FaAngleDoubleRight className='skill-icon'></FaAngleDoubleRight> */}
                                                                    <span className="skill-content">{bull}</span>
                                                                </li>
                                                            )
                                                        })}                                               
                                                </ul>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div> {/*!block-row */}
                            </div>
                            <Experience/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume
