import React from 'react';

const Menu = ({ projects }) => {
    return (
        <div className="portfolio-grid three-columns shuffle">
            {projects.map((item) => {
                const { id, title, category, img, link } = item;
                return (
                    <figure key={id} className="item standard">
                        <div className="portfolio-item-img">
                            <img src={process.env.PUBLIC_URL+img} alt={title} />
                            <a href={link} target="_blank" rel="noreferrer" className=""></a>
                        </div>
                        <a href={link} target="_blank" rel="noreferrer" className="">
                            <i className="fa fa-link"></i>
                        </a>
                        
                        <h4 className="name">{title}</h4>
                        <span className="category">{category}</span>
                    </figure>
                );
            })}
        </div>
    );
};

export default Menu;
