import React, { useState } from 'react';
import data from './data';
import Card from './Card';

const Expertise = () => {
    const [expertise, setExpertise] = useState(data);
    
    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="p-50" />
                    <div className="block-title">
                        <h2>Areas of Expertise</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <div className="info-list-w-icon">
                        <Card skills={expertise.slice(0, 2)}/>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-6">
                    <div className="info-list-w-icon">

                        <Card skills={expertise.slice(-2)} />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expertise
