import React from 'react';

const Categories = ({ categories, active, filterItems }) => {
    return (
        <ul className="portfolio-filters">
            {categories.map((category, index) => {
                return (
                    <li key={index} className={active ? 'active' : ''} onClick={() => filterItems(category)}>
                        <a className="filter btn btn-primary btn-link" href="javascript:void(0)">{category}</a>
                    </li>
                );
            })}
        </ul>
    );
};

export default Categories;
