import React from 'react'

const Card = ({skills}) => {
    return (
        <>
            {skills.map((exp, index) => {
                const { icon, title, desc } = exp;
                return (
                    <div key={index} className="info-block-w-icon">
                    <div className="ci-icon">
                        <i className={`linecons linecons-${icon}`} />
                    </div>
                    <div className="ci-text">
                        <h4>{title}</h4>
                        <p>{desc}</p>
                    </div>
                </div>
                )
            })}
        </>
    );
};

export default Card
