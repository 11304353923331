const data = [
    {
        id: 1,
        title: 'Developer',
        timeline: 'Oct 2018 - Apr 2020',
        name: 'zu.com, Saskatoon',
        desc: ["Developed and structured feature-rich websites, ensuring that all needs were met, and company policies and procedures followed; created high-quality code.", "Built reusable frontend components using KSS style guide and integrate them to Drupal backend using PHP Twig template engine.", "Performed Security updates and patching of Drupal and WordPress websites.", "Collaborated with project managers and creative teams to deliver efficient solutions and achieve desired functionality.", "Conducted testing and quality checks of the websites on multiple devices using Google dev console and BrowserStack testing tools.", "Pitched in to assist senior developers to meet pressing deadlines during under-staffed periods.","Provided effort estimates and timeline for the upcoming projects requirements."],
    },
    {
        id: 2,
        title: 'Web Developer',
        timeline: 'Aug 2017 - Oct 2018',
        name: '2 Web Design, Saskatoon',
        desc: ["Developed custom CMS using CodeIgniter PHP framework, REST API and MVC architecture.", "Designed and developed dynamic websites from start to finish using WordPress and CodeIgniter.", "Revamped WordPress websites by minimizing the use of plugins and implemented enhancements that significantly improved web functionality and speed.", "Built interactive web-based applications such as registration and membership system, event scheduler and learning management system.", "Provided support and customer service to resolve technical bugs and issues in the websites.", "Participated in the client meetings to discuss project requirements and provide technical solutions.","Responsible for SSL installations, hosting and maintaining web applications and websites on Linux and Windows-based hosting servers."],
    },
    {
        id: 3,
        title: 'Web Developer',
        timeline: 'Jun 2017 - Aug 2017',
        name: 'Blairmore Media Inc., Saskatoon',
        desc: ["Developed and customized e-commerce websites using WordPress.", "Maintained the current websites and added more appealing features.","Recommended solutions, tools, best practices and alternative methods to build creative, optimized and fast websites."],
    },
    {
        id: 4,
        title: 'Web Developer',
        timeline: 'Mar 2017 - Aug 2017',
        name: 'Refresh Inc., Saskatoon',
        desc: ["Developed creative and responsive websites using HTML, CSS, JavaScript and WordPress.","Redesigned sections of the website to ensure a more attractive and user-friendly experience."],
    },
    {
        id: 5,
        title: 'Freelance Web Developer',
        timeline: 'May 2016 - Present',
        name: 'Freelance, Saskatoon',
        desc: ["Developed websites climalogik.ca, elvisasong.com, sopankurkute.com, and timkip.com", "Responsible for hosting, maintenance and providing email support to the clients.","Configuration of SEO and Google analytics."],
    },
    {
        id: 6,
        title: 'System Support Analyst',
        timeline: '2011 - 2015',
        name: 'Cognizant Technology Solutions Pvt Ltd., India',
        desc: ["Monitoring, configuring and troubleshooting of banking applications and web services.", "Provide solutions to clients by identifying issues and by providing root cause analysis.","Facilitate IT process improvement through creation of technical articles and trainings."],
    },
    {
        id: 7,
        title: 'UNIX System Administrator',
        timeline: '2008 - 2011',
        name: 'Capgemini Consulting India Pvt Ltd., India',
        desc: ["Perform day-to-day system administration tasks for overseas clients vie phone, internet and email.", "Support and maintenance of Unix servers located at remote data centers.","Develop extensive bash/K shell scripts for automation and fine-tuning of server processes."],
    },
    {
        id: 8,
        title: 'Trainee Engineer (Intern)',
        timeline: '2006 - 2007',
        name: 'Kopera Software Pvt. Ltd, India',
        desc: ["Development, analysis and implementation of key modules of Electronic Program Guide, which is an application located within a set top box connected to TV set to sort programs and TV channels according to type, location, time and personal interest of a user."],
    },
];
export default data;
