import React from 'react'

const Company = ({ company}) => {
    return (
        <>
            {company.map((exp, index) => {
                const { title, timeline, name, desc } = exp;
                return (
                    <div key={index} className= "timeline-item clearfix col-lg-12" >
                        <h5 className="item-period current">{timeline}</h5>
                        <span className="item-company">{name}</span>
                        <h4 className="item-title">{title}</h4>
                        <ul>
                            {desc.map((duty, index) => {
                                return (
                                    <li key={index}>{duty}</li>
                                )
                            })}
                        </ul>
                        
                </div >
                );
            })}
        </>
    )
}

export default Company

    